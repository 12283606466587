import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import GeoList from './component/GeoList';
import SearchFences from './component/searchFences';
import About from './component/About';
import './App.css';
import { Container, Row,Col } from 'reactstrap';
import Nav from './component/Nav';
//import SimpleMap from './component/Map';
//import SimpleMap from './component/Newmap';
//import SimpleMap from './component/Mappen';
import SimpleMap from './component/MapContainer';
class App extends Component {
render() {
	return (
	<Router>
		<div className="App">
    <Nav />
    <Container className="themed-container" fluid={true}>
    <Row>
      <Col>
			<Switch>
			<Route exact path='/' component={SearchFences}></Route>
			<Route exact path='/about' component={About}></Route>
			<Route exact path='/map' component={SimpleMap}></Route>

			</Switch>
      </Col>
    </Row>
    </Container>
		</div>
	</Router>
);
}
}

export default App;
