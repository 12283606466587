import React,{useEffect, useState, useRef} from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { Redirect } from 'react-router-dom'
import { Spinner, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Radar from 'radar-sdk-js';

const ViewContainer = () => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [ searchFences, setSearchFences ] = useState([]);

useEffect(() => {
 setIsLoaded(false);
  Radar.initialize('prj_test_sk_866c0b785d74da6e921b77b944671ee1380ffbe7');
  searchGeoFences()
}, []);

const searchGeoFences = () => {
  Radar.searchGeofences({
    radius: 10000,
    //tags: ['venue'],
    limit: 10
  }, function(err, result) {
    if (!err) {
      // do something with result.geofences
      console.log('searchGeoFences !');
      console.log(result);
      console.log('searchGeoFences!');
      console.log(result);
        setSearchFences(result.geofences)
      setIsLoaded(true);
    }
  });

}

  if (!isLoaded) {
      return (
          <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
      )
  } else if(isLoaded && searchFences.length >0){
  return   (<><h1>Geofences within 10 Km</h1>

    <ListGroup>{searchFences.map(geofences => <ListGroupItem key={geofences._id} ><Link to="/map">{geofences.description} {geofences.createdAt}</Link></ListGroupItem>)}</ListGroup></>
  )
} else {
  return(<div><h1>Geofences within 10 Km</h1>
    No geofences, go to Map and start <Link to="/map">tracking</Link></div>)
}
}

export default ViewContainer;
