import React,{useEffect, useState, useRef} from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { Spinner, ListGroup, ListGroupItem, Button } from 'reactstrap';
import Radar from 'radar-sdk-js';

const MapContainer = () => {
  const [map, setMap] = React.useState(null)
  const [ currentPosition, setCurrentPosition, currentRadarPosition, setCurrentRadarPosition ] = useState({});

  const [message, setMessage] = useState( '' );

const [distanceMess, setDistanceMess] = useState( '' );

  const mapStyles = {
    height: "80vh",
    width: "100%"};



    /*const defaultCenter = {
    lat: 41.3851, lng: 2.1734
  }*/

  const success = position => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      //accuracy: 65
    }
    setCurrentPosition(currentPosition);
  };

  /*const coordinates = position => {
  const currentPosition = {
  lat: position.latitude,
  lng: position.longitude,
  //accuracy: 65
}
setCurrentPosition(currentPosition);
};*/

/*useEffect(() => {
  // this is only executed once
  //navigator.geolocation.getCurrentPosition(success);
  Radar.getLocation(function(err, result) {
    if (!err) {
      // do something with result.location
      const currentLocation = {
        lat: result.location.latitude,
        lng: result.location.longitude
      }
      setCurrentPosition(currentLocation);
    }
  });
  console.log(currentPosition);
  Radar.initialize('prj_test_sk_866c0b785d74da6e921b77b944671ee1380ffbe7');
  setInterval(Tracker, 30000);
  setInterval(offlineTracker, 1000);
}, [])*/


useEffect(() => {
  Radar.getLocation(function(err, result) {
    if (!err) {
      // do something with result.location
      const currentLocation = {
        lat: result.location.latitude,
        lng: result.location.longitude
      }
      setCurrentPosition(currentLocation);
    }
  });
  console.log(currentPosition);
  Radar.initialize('prj_test_sk_866c0b785d74da6e921b77b944671ee1380ffbe7');
const interval = setInterval(() => {
  //console.log('This will run every 30 second!');
  Tracker()
}, 30000);

const interval2 = setInterval(() => {
  offlineTracker()
}, 1000);
return () => {
  clearInterval(interval);
  clearInterval(interval2);
}

}, []);



const offlineTracker = () => {
  Radar.getLocation(function(err, result) {
    if (!err) {
      console.log('offlineTracker This will run every sec!');
      // do something with result.location
      const currentLocation = {
        lat: result.location.latitude,
        lng: result.location.longitude
      }
      setCurrentPosition(currentLocation);
    }
  });
}

const Tracker = () => {
  //navigator.geolocation.getCurrentPosition(success);
  //console.log(currentPosition);

  //Radar.trackOnce(currentPosition, function(err, result) {
  Radar.trackOnce( function(err, result) {
    if (!err) {
      //console.log(result.user.geofences[0].description);
      //  console.log(result);
      console.log('This will run every 30 second!');
      console.log(result.location);
      //coordinates();
      /*  currentPosition = {
      lat: result.location.latitude,
      lng: result.location.longitude
    }*/
    const currentLocation = {
      lat: result.location.latitude,
      lng: result.location.longitude
    }
    calcDistance(currentLocation);
    setCurrentPosition(currentLocation);
    console.log(currentPosition);
    console.log(currentLocation);
    //  setCurrentPosition(currentPosition);
    // do something with result.events, result.user
    if(result.user.geofences[0] !== undefined && result.user.geofences[0].description !== undefined){
      console.log(result.user.geofences[0].description);
      setMessage(`Congrats! You have found ${result.user.geofences[0].description}!` );
calcDistance(currentLocation);
    } else {
      setMessage(`Tracking every 30 sec: Nothing found so far..` );

    }

  } else if (err){
    console.log(err);
  //  clearInterval(interval);
  }
});
};

const calcDistance = (origin) => {
  Radar.searchGeofences({
    radius: 10000,
    //tags: ['venue'],
    limit: 1
  }, function(err, result) {
    if (!err) {
      // do something with result.geofences
      console.log('searchGeoFences !');
      console.log(result);
      console.log('searchGeoFences!');
      console.log(result);
      //  setSearchFences(result.geofences)
      //setIsLoaded(true);
    const destination = {
        lat: result.geofences[0].geometryCenter.coordinates[1],
        lng: result.geofences[0].geometryCenter.coordinates[0]
      }
      getDistance(origin, destination);
    }
  });
  const getDistance = (orig,dest) => {
    console.log(dest)
  Radar.getDistance({
    origin: {
      latitude: orig.lat,
      longitude: orig.lng
    },
    destination: {
      latitude: dest.lat,
      longitude: dest.lng
    },
    modes: [
      'foot',
      'car'
    ],
    units: 'metric'
  }, function(err, result) {
    if (!err) {
      // do something with result.routes
      console.log("Distance");
      console.log(result);
      console.log(result.routes.foot.distance.text);
      setDistanceMess(`Distance to nearest Xspot: ${result.routes.foot.distance.text}`)
    }
  });
}

}


const onLoad = React.useCallback(function callback(map) {
const bounds = new window.google.maps.LatLngBounds();
setMap(map)
}, [])

const onUnmount = React.useCallback(function callback(map) {
setMap(null)
//clearInterval(interval);
}, [])

return (
  <>
  <div>{message}<br/></div>
  <div>{distanceMess}</div>
  <div>Lat: {currentPosition.lat} Long: {currentPosition.lng}<br/></div>

  <LoadScript
  googleMapsApiKey={process.env.REACT_APP_API_KEY}>
  <GoogleMap
  mapContainerStyle={mapStyles}
  zoom={13}
  center={currentPosition}
  onLoad={onLoad}
  onUnmount={onUnmount}
  >
  <Marker position={currentPosition}/>
  </GoogleMap>
  </LoadScript>
  </>
)
}

export default MapContainer;
