import React from 'react';

function About () {
	return <div>
		<h1>This is a Geofencing app!</h1>
<p>Built with Axios, Atom, GitHub, Google Maps, ReactJS, Radar, Render and Reactstrap..</p>
		Read more about us at:
		<a href="/about/">
			https://xspot/about/
		</a>
	</div>
}
export default About;
