import React from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import Radar from 'radar-sdk-js';
import { Spinner, ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
export default class GeoList extends React.Component {
  state = {
    geo: [],
    isLoaded: false,
    redirect: false
  }



  componentDidMount() {
    Radar.initialize('prj_test_sk_866c0b785d74da6e921b77b944671ee1380ffbe7');
    this.searchGeoFences();

    /*axios.get(`https://api.radar.io/v1/geofences`,
      {
         headers: {
            Authorization : 'prj_test_sk_866c0b785d74da6e921b77b944671ee1380ffbe7'
         }
      })
      .then(res => {
      //  const geofences = res.data;
      //  console.log(geofences);
      this.setState({
          isLoaded: true,
          geo: res.data.geofences
        });
      //  this.setState({ geofences });
        console.log(this.state.geo);
      })*/
  }

  searchGeoFences = () => {
    Radar.searchGeofences({
      radius: 1000,
      //tags: ['venue'],
      limit: 10
    }, function(err, result) {
      if (!err) {
        // do something with result.geofences
        console.log('searchGeoFences!');
        console.log(result);
        this.setState({
            isLoaded: true,
            geo: result.geofences
          });
          console.log(this.state);
      }
    });

  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/map' />
    }
  }

  render() {
    const { geo, isLoaded } = this.state;
  /*  const {itemInfo} = (
      <ul>
        {geo.map(geofences => <li key={geofences._id}>{geofences.description}</li>)}
      </ul>
    );

    if (!isLoaded) {
      return <div>Loading</div>;
    } else {
      return {itemInfo};
    }*/
if (!isLoaded) {
    return (
        <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
    )
} else {
  return (<><h1>Geofences</h1>
  <ListGroup>{geo.map(geofences => <ListGroupItem key={geofences._id} ><Link to="/map">{geofences.description} {geofences.createdAt}</Link></ListGroupItem>)}</ListGroup></>
)
}

  }


/*
  render() {
      var { items, isLoaded } = this.state;
      var itemInfo = items.map(item => (
        <div key={item.colors.id}>Hex:{item.colors.hex}</div>
      ));

      if (!isLoaded) {
        return <div>{itemInfo}</div>;
      } else {
        return <div className="App">{itemInfo}</div>;
      }
    }*/



}
